import * as React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/team/Hero'
import EmployePerks from '../components/team/EmployePerks'
import BottomCTASection from '../components/team/BottomCTASection'
import MapSection from '../components/team/MapsSection'

const HtmlHead = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        property="og:image"
        content="https://enroll.rhc.care/wp-content/uploads/2021/06/rockaway-reviews.jpg"
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="CDPAP - (Consumer Directed Personal Assistance Program) Get Paid To Care For Your Loved One"
      />
      <meta property="og:url" content="https://enroll.rhc.care/cdpap/" />
      <meta property="og:site_name" content="Rockaway Home Care" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="CDPAP - (Consumer Directed Personal Assistance Program) Get Paid To Care For Your Loved One"
      />
      <meta
        name="twitter:image"
        content="https://enroll.rhc.care/wp-content/uploads/2021/06/rockaway-reviews.jpg"
      />
      <link
        rel="icon"
        href="https://enroll.rhc.care/wp-content/uploads/2021/05/cropped-favi0-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        href="https://enroll.rhc.care/wp-content/uploads/2021/05/cropped-favi0-192x192.png"
        sizes="192x192"
      />
      <link
        rel="apple-touch-icon"
        href="https://enroll.rhc.care/wp-content/uploads/2021/05/cropped-favi0-180x180.png"
      />
      <meta
        name="msapplication-TileImage"
        content="https://enroll.rhc.care/wp-content/uploads/2021/05/cropped-favi0-270x270.png"
      />
      <title>
        CDPAP - (Consumer Directed Personal Assistance Program) Get Paid To Care
        For Your Loved One
      </title>
    </Helmet>
  )
}

// markup
const IndexPage = () => {
  return (
    <>
      <HtmlHead />
      <Header
        phoneNumber={'(516) 239-8693'}
        phoneCta="call us today on"
        ctaText="Join our team! Enroll online or"
      />
      <main>
        <Hero />
        <div className="bg-[#EBF2F8]">
          <EmployePerks />
        </div>
        <div className="bg-gradient-to-b from-[#EBF2F8] via-transparent to-[#F4F1F8]">
          <MapSection />
        </div>
        <div className="bg-gradient-to-b from-[#F4F1F8] to-transparent">
          <BottomCTASection />
        </div>
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
