import React from 'react'
import Form from './Form'

import Bg from './assets/hero_bg.png'

const heroWrapperImg = {
  backgroundImage: `url(${Bg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}

const Hero = () => {
  return (
    <div
      className="px-4 pt-8 pb-20 lg:px-16 lg:pt-12 text-white overflow-hidden relative"
      style={heroWrapperImg}>
      <div className="flex flex-col lg:flex-row max-w-6xl mx-auto">
        <div className="flex-grow basis-3/5 pb-20 lg:pb-0 lg:pr-5 text-center lg:text-left">
          <h2 className="text-xl lg:text-4xl font-bold mb-3 tracking-tight">
            Join our team
          </h2>
          <h1 className="text-4xl px-4 lg:px-0 lg:text-6xl lg:leading-snug font-bold">
            We’re hiring Home Health Aide & Personal Care Assistant
          </h1>
          <div className="font-bold text-sm mt-12 px-7 leading-6 lg:px-0 lg:font-normal lg:text-lg lg:my-8">
            <p>
              Serving the community for over 25, we take the headache out of
              home care with easy enrolment.
            </p>
          </div>
        </div>
        <div className="flex justify-center flex-grow basis-2/5 z-[1] text-dark">
          <div className="w-full border border-grey-200 rounded-2xl bg-white py-6 max-w-lg px-8">
            <Form />
          </div>
        </div>
      </div>
      <div className="bg-[#EBF2F8] h-[280px] rounded-[50%] w-[120%] -translate-x-[10%]  translate-y-1/2 absolute bottom-0 left-0 right-0 mx-auto"></div>
    </div>
  )
}

export default Hero
