import React from 'react'
import Button from '../Button'

const scrollToEligibilityForm = () => {
  document
    .querySelector('#eligibilityForm>h3')
    .scrollIntoView({ behavior: 'smooth', block: 'center' })
}

const BottomCTASection = () => {
  return (
    <div className="px-6 py-20 md:px-12">
      <h2 className="text-4xl font-semibold text-center mb-14">
        Join our team!
      </h2>
      <div className="flex flex-col items-center">
          <Button onClick={scrollToEligibilityForm}>Enroll online now!</Button>
        <p className="font-bold text-center text-lg my-6">
          <span className="inline-block">Call Us Today on</span>{' '}
          <a
            className="inline-block underline decoration-2 decoration-brand-200"
            href="tel:(516)2398693">
            (516) 239-8693
          </a>
        </p>
      </div>
    </div>
  )
}

export default BottomCTASection
