import React from 'react'

import image_0 from './assets/perk_0.svg'
import image_1 from './assets/perk_1.svg'
import image_2 from './assets/perk_2.svg'
import image_3 from './assets/perk_3.svg'
import image_4 from './assets/perk_4.svg'
import image_5 from './assets/perk_5.svg'
import image_6 from './assets/perk_6.svg'
import image_7 from './assets/perk_7.svg'
import image_8 from './assets/perk_8.svg'
import image_9 from './assets/perk_9.svg'

const tiles = [
  {
    title: 'Paid time off',
    image: image_0,
  },
  {
    title: 'Direct deposit',
    image: image_1,
  },
  {
    title: 'Health insurance',
    image: image_2,
  },
  {
    title: 'Same day pay',
    image: image_3,
  },
  {
    title: 'Holiday pay',
    image: image_4,
  },
  {
    title: 'Career development',
    image: image_5,
  },
  {
    title: 'Employee referral program',
    image: image_6,
  },
  {
    title: 'Free cell phone',
    image: image_7,
  },
  {
    title: 'Patient referral program',
    image: image_8,
  },
  {
    title: 'Flexible spending account',
    image: image_9,
  },
]

const Tile = ({ title, image }) => {
  return (
    <div
      className="flex flex-col justify-center items-center p-6 border border-grey-200 rounded-xl flex-shrink-0 m-2 bg-white">
      <img src={image} alt={title} className="mb-4" />
      <h4 className="font-semibold text-xl text-center">{title}</h4>
    </div>
  )
}

const ReasonsScroll = () => {
  return (
    <div className="grid grid-cols-2 lg:grid-cols-5 md:justify-center md:px-10 md:max-w-7xl mx-auto">
      {tiles.map((t, i) => (
        <Tile key={i} {...t} />
      ))}
    </div>
  )
}

const WhyChooseSection = () => {
  return (
    <div className="pb-32 pt-16">
      <h2 className="text-4xl text-center font-semibold mb-10 px-4 sm:px-10">
        Employe Perks & Benefits
      </h2>
      <ReasonsScroll />
    </div>
  )
}

export default WhyChooseSection
