import React from 'react'

import map_img from './assets/map_area.png'

const Map = () => {
  return (
    <img
      src={map_img}
      style={{
        height: 'auto',
        backgroundImage: `url(${map_img})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}></img>
  )
}

const MapSection = () => {
  return (
    <div className="py-16">
      <h2 className="text-4xl text-center font-semibold mb-10 px-4 sm:px-10">
        Our locations
      </h2>
      <Map />
    </div>
  )
}

export default MapSection
