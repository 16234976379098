import React, { useRef } from 'react'
import classnames from 'classnames'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Button from '../Button'
import parser from 'ua-parser-js'
import Modal, { ModalHandlers } from '../Portal'

import success_icon from '../../images/success.svg'
import failure_icon from '../../images/failure.svg'
import { useState } from 'react'

const inputClass = classnames(
  'flex-grow min-w-0 w-full border-2 border-brand-500 rounded-lg h-12 px-2'
)

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const EligibilitySchema = Yup.object().shape({
  name: Yup.string().max(50, 'Too Long!').required('Required'),
  last_name: Yup.string().max(50, 'Too Long!').required('Required'),
  email: Yup.string().email('Email is not valid').required('Required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Required'),
})

const StyledError = ({ children }) => (
  <div className="text-red-400 mt-1 text-sm">{children}</div>
)

const deviceData = () => {
  const { browser, device, os } = parser(navigator.userAgent)
  return {
    user_agent: navigator.userAgent,
    browser: browser.name,
    device: device.model,
    device_type: device.type,
    device_vendor: device.vendor,
    os: os.name,
  }
}

const adwordsData = () => {
  const params = Object.fromEntries(new URLSearchParams(window.location.search))
  return {
    source_url: window.location.href,
    ...params,
  }
}

const submitRequest = (values) => {
  const postURL =
    process.env.NODE_ENV === 'production'
      ? 'https://enroll-rhc-worker.rockaway-email.workers.dev/cdpap-enroll'
      : 'http://localhost:8787/cdpap-enroll'

  return fetch(postURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  })
}

const modal_content = {
  success: {
    icon: success_icon,
    title: 'Success!',
    message: 'Your details have been submitted.',
    btn: 'Close',
  },
  failure: {
    icon: failure_icon,
    title: 'Uh oh!',
    message: 'Looks like something went wrong.',
    btn: 'Close',
  },
}

const FeedbackModal = ({ status, action }) => {
  const content = modal_content[status]

  return (
    <div className="flex flex-col gap-6 items-center">
      <img width={72} height={72} src={content.icon} />
      <div>
        <h4
          className={classnames(
            'text-3xl text-center',
            status === 'success' ? 'text-brand-500' : 'text-carmine-500'
          )}>
          {content.title}
        </h4>
        <h5
          className={classnames(
            'text-2xl text-center',
            status === 'success' ? 'text-brand-500' : 'text-carmine-500'
          )}>
          {content.message}
        </h5>
      </div>
      <Button full onClick={action}>
        {content.btn}
      </Button>
    </div>
  )
}

const EligibilityForm = () => {
  const modal = useRef<ModalHandlers>()
  const [status, setStatus] = useState<null | 'success' | 'failure'>(null)

  const showMessage = (status) => {
    setStatus(status)
    modal.current?.openModal()
  }

  return (
    <>
      <Modal ref={modal}>
        <FeedbackModal
          status={status}
          action={() => modal.current?.closeModal()}
        />
      </Modal>
      <Formik
        initialValues={{
          name: '',
          last_name: '',
          email: '',
          phone: '',
          servicing_areas: '',
          is_hha: false,
          is_pca: false,
          training: false,
        }}
        validateOnChange={false}
        validationSchema={EligibilitySchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const data = {
            ...adwordsData(),
            ...deviceData(),
            ...values,
          }
          submitRequest(data)
            .then(() => {
              if (window?.gtag) {
                window.gtag('event', 'conversion', {
                  send_to: 'AW-10804368874/iO0QCLjmpYIDEOqr9p8o',
                })
                window.gtag('event', 'Submit', {
                  event_category: 'lead',
                  event_label: 'Form',
                  value: 1,
                })
              }
              if (window?.fbq) {
                window.fbq('track', 'Lead')
              }
              resetForm()
              showMessage('success')
            })
            .catch((e) => {
              console.error(e)
              showMessage('failure')
            })
            .finally(() => {
              setSubmitting(false)
            })
        }}>
        {({ isSubmitting, values }) => (
          <Form id="eligibilityForm" className="space-y-4" autoComplete="on">
            <h3 className="font-bold text-2xl">Find out if you're eligible</h3>
            <div>
              <label
                htmlFor="field_name"
                className="block font-bold text-sm py-2">
                Name
              </label>
              <div className="flex flex-col xs:flex-row gap-4">
                <div className="flex-grow">
                  <Field
                    id="field_name"
                    name="name"
                    autoComplete="given-name"
                    placeholder="First Name"
                    className={inputClass}
                  />
                  <ErrorMessage component={StyledError} name="name" />
                </div>
                <div className="flex-grow">
                  <Field
                    name="last_name"
                    autoComplete="family-name"
                    placeholder="Last Name"
                    className={inputClass}
                  />
                  <ErrorMessage component={StyledError} name="last_name" />
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="field_email"
                className="block font-bold text-sm py-2">
                Email
              </label>
              <div className="flex flex-row">
                <Field
                  id="field_email"
                  type="email"
                  autoComplete="email"
                  name="email"
                  placeholder="Email"
                  className={inputClass}
                />
              </div>
              <ErrorMessage component={StyledError} name="email" />
            </div>
            <div>
              <label
                htmlFor="field_phone"
                className="block font-bold text-sm py-2">
                Phone number
              </label>
              <div className="flex flex-row">
                <Field
                  id="field_phone"
                  type="phone"
                  autoComplete="tel"
                  name="phone"
                  placeholder="Enter phone number"
                  className={inputClass}
                />
              </div>
              <ErrorMessage component={StyledError} name="phone" />
            </div>
            <div>
              <label
                htmlFor="servicing_areas"
                className="block font-bold text-sm py-2">
                What areas are you interested in servicing?
              </label>
              <div className="flex flex-row">
                <Field
                  as="select"
                  // multiple={true}
                  id="servicing_areas"
                  className={`${inputClass} form-multiselect`}
                  name="servicing_areas"
                  placeholder="Select location(s)">
                  <option disabled selected value="">
                    Select location(s)
                  </option>
                  <option>Area 1</option>
                  <option>Area 2</option>
                  <option>Area 3</option>
                  <option>Area 4</option>
                </Field>
              </div>
            </div>
            <div>
              <div className="flex flex-col gap-2">
                <div className="block font-bold text-sm py-2">Are you?</div>
                <div className="flex items-center font-bold text-sm">
                  <Field
                    className="w-6 h-6 mr-4 form-checkbox border-2 rounded-md"
                    id="hha_checkbox"
                    name="is_hha"
                    type="checkbox"
                  />
                  <label htmlFor="hha_checkbox">
                    A licensed Home Health Aide (HHA)
                  </label>
                </div>
                <div className="flex items-center font-bold text-sm">
                  <Field
                    className="w-6 h-6 mr-4 form-checkbox border-2 rounded-md"
                    id="pca_checkbox"
                    name="is_pca"
                    type="checkbox"
                  />
                  <label htmlFor="pca_checkbox">
                    A licensed Personal Care Aid (PCA)
                  </label>
                </div>
                <div className="flex items-center font-bold text-sm">
                  <Field
                    className="w-6 h-6 mr-4 form-checkbox border-2 rounded-md"
                    id="training_checkbox"
                    name="traingin"
                    type="checkbox"
                  />
                  <label htmlFor="training_checkbox">
                    Looking for training
                  </label>
                </div>
              </div>
              <ErrorMessage component={StyledError} name="has_medicaid" />
            </div>
            <div className="mt-6">
              <Button type="submit" disabled={isSubmitting} full>
                Enroll online now!
              </Button>
              <a
                className="block underline decoration-2 decoration-brand-200 font-bold text-center mt-4 text-lg"
                href={`tel:(516) 239 8693`}>
                Or call us on 516 239 8693
              </a>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default EligibilityForm
